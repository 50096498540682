<template>
    <div class="mt-5">
        <h3>Nameserver ändern</h3>
        <v-row dense class="mt-3 mb-3">
            <v-col md="6">
                <v-text-field label="Erster Nameserver" v-model="ns1" />
            </v-col>
            <v-col md="6">
                <v-text-field label="Zweiter Nameserver" v-model="ns2" />
            </v-col>
            <v-col md="12">
                <v-btn color="orange" class="white--text">Nameserver ändern</v-btn>
            </v-col>
            <v-col md="12">
                <v-alert
                    class="mt-5"
                    border="bottom"
                    colored-border
                    type="warning"
                    elevation="2"
                >
                Diese Funktion ist nur für Experten gedacht! Bei falsch konfigurierten Nameservern ist die Domain ggf. nicht mehr auflösbar.
                </v-alert>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import xeonserv from '../../../apis/xeonserv'

export default {
    data() {
        return {
            ns1: '',
            ns2: ''
        }
    },
    props: {
        domain: {
            type: Object
        }
    },
    created() {
        this.ns1 = this.domain.ns1
        this.ns2 = this.domain.ns2
    },
    watch: {
        domain() {
            this.ns1 = this.domain.ns1
            this.ns2 = this.domain.ns2
        }
    },
    methods: {
        updateNameservers() {
            xeonserv().put('/domains/' + this.domain.id, { ns1: this.ns1, ns2: this.ns2 }).then(res => this.$parent.$emit('setdomain', res.data.data))
        }
    }
}
</script>