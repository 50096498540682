<template>
    <v-row class="mt-5">
        <v-col md="8">
            <v-row dense>
                <v-col md="12">
                    <v-text-field label="Name" outlined readonly dense :value="domain.sld + '.' + domain.tld" />
                </v-col>
                <v-col md="12">
                    <v-text-field label="Restlaufzeit" outlined readonly dense :value="remainingTime" />
                </v-col>
            </v-row>
        </v-col>
        <v-col md="4">
            <v-btn block class="mb-2" color="primary">Verlängern</v-btn>
        </v-col>
    </v-row>
</template>

<script>
import interval from '../../../util/interval'

export default {
    data() {
        return {
            now: new Date()
        }
    },
    timers: {
        updateNow: { time: 1000, autostart: true, repeat: true, callback() { this.now = new Date() }}
    },
    props: {
        domain: {
            type: Object
        },
        service: {
            type: Object
        }
    },
    computed: {
        remainingTime() {
            this.now;
            return interval.format(interval.until(this.service.expire_at))
        }
    }
}
</script>