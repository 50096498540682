<template>
    <div class="mt-5">
        <div style="margin-bottom: 1px; height: 40px;">
            <div style="float: right;">
                <v-dialog max-width="600px" v-model="open">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" v-bind="attrs" v-on="on">Neuer Eintrag</v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span v-if="oldRecord == undefined" class="headline">Neuer DNS-Eintrag</span>
                            <span v-if="oldRecord != undefined" class="headline">DNS-Eintrag bearbeiten</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row dense>
                                    <v-col md="6">
                                        <v-select :readonly="oldRecord != undefined" label="Typ" :items="['A','AAAA','MX','SRV','CNAME','TXT'].map(t => ({ text: formatType(t), value: t }))" v-model="record.type" />
                                    </v-col>
                                    <v-col md="6">
                                        <v-text-field :readonly="oldRecord != undefined" label="Name (z.B. www)" v-model="record.name" />
                                    </v-col>
                                    <v-col md="12" v-if="record.type == 'A' || record.type == 'AAAA'">
                                        <v-text-field label="IP Adresse" v-model="record.address" />
                                    </v-col>
                                    <v-col md="12" v-if="record.type == 'SRV'">
                                        <v-select label="Dienst" :items="[
                                            { text: 'Minecraft', value: 'MINECRAFT' },
                                            { text: 'Teamspeak', value: 'TEAMSPEAK' },
                                            { text: 'Anderer', value: 'OTHER' }
                                        ]" v-model="record.srvService" />
                                    </v-col>
                                    <v-col md="6" v-if="record.type == 'SRV' && record.srvService == 'OTHER'">
                                        <v-select label="Protokoll" :items="[
                                            { text: 'TCP', value: '_tcp' },
                                            { text: 'UDP', value: '_udp' }
                                        ]" v-model="record.srvProtocol" />
                                    </v-col>
                                    <v-col md="6" v-if="record.type == 'SRV' && record.srvService == 'OTHER'">
                                        <v-text-field label="Dienstname" v-model="record.srvName" />
                                    </v-col>
                                    <v-col md="6" v-if="record.type == 'SRV' || record.type == 'MX'">
                                        <v-text-field label="Host" v-model="record.host" />
                                    </v-col>
                                    <v-col md="12" v-if="record.type == 'CNAME'">
                                        <v-text-field label="Host" v-model="record.host" />
                                    </v-col>
                                    <v-col md="6" v-if="record.type == 'SRV'">
                                        <v-text-field min="0" max="65535" type="number" label="Port" v-model="record.port" />
                                    </v-col>
                                    <v-col md="6" v-if="record.type == 'SRV' || record.type == 'MX'">
                                        <v-text-field min="0" max="10000" type="number" label="Priorität" v-model="record.priority" />
                                    </v-col>
                                    <v-col md="6" v-if="record.type == 'SRV'">
                                        <v-text-field min="0" max="10000" type="number" label="Gewichtung" v-model="record.weight" />
                                    </v-col>
                                    <v-col md="12" v-if="record.type == 'TXT'">
                                        <v-textarea label="Text" v-model="record.text" />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="open = false">Abbrechen</v-btn>
                            <v-btn v-if="oldRecord == undefined" color="primary" @click="createRecord()">Anlegen</v-btn>
                            <v-btn v-if="oldRecord != undefined" color="primary" @click="updateRecord()">Bearbeiten</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>
        <v-simple-table>
            <thead>
                <tr>
                    <th class="text-left">
                        Typ
                    </th>
                    <th class="text-left">
                        Name
                    </th>
                    <th class="text-left">
                        Wert
                    </th>
                    <th class="text-left">
                        Aktionen
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                v-for="record in records"
                :key="record.type + ' ' + record.name + ' ' + record.data"
                >
                    <td>{{ record.type }}</td>
                    <td>{{ record.name }}</td>
                    <td>{{ record.data }}</td>
                    <td>
                        <v-btn small color="primary" class="mr-1" @click="editRecord(record)">Bearbeiten</v-btn>
                        <v-btn small color="red" class="white--text" @click="deleteRecord(record)">Löschen</v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
import xeonserv from '../../../apis/xeonserv'

const DEFAULT_RECORD = {
    type: 'A',
    name: '',
    address: '',
    srvService: 'MINECRAFT',
    srvProtocol: '_tcp',
    srvName: '_minecraft',
    host: '',
    port: 25565,
    priority: 10,
    weight: 0,
    text: ''
}

export default {
    props: {
        domain: {
            type: Object
        }
    },
    data() {
        return {
            open: false,
            record: {...DEFAULT_RECORD},
            records: [],
            oldRecord: undefined
        }
    },
    watch: {
        srvService() {
            switch(this.srvService) {
                case 'MINECRAFT':
                    this.srvPort = 25565
                    this.srvProtocol = '_tcp'
                    this.srvName = '_minecraft'
                    break
                case 'TEAMSPEAK':
                    this.srvPort = 9987
                    this.srvProtocol = '_udp'
                    this.srvName = '_ts3'
                    break
                case 'OTHER':
                    this.srvPort = 0
                    this.srvProtocol = '_tcp'
                    this.srvName = ''
                    break
            }
        },
        open() {
            if(!this.open) {
                this.record = {...DEFAULT_RECORD}
                this.oldRecord = undefined
            }
        }
    },
    created() {
        this.loadRecords()
    },
    methods: {
        loadRecords() {
            xeonserv().get('/domains/' + this.domain.id + '/records').then(res => this.records = res.data.data)
        },
        editRecord(record) {
            this.oldRecord = record
            this.record = this.deserialize(record)
            this.open = true
        },
        deleteRecord(record) {
            console.log(record)
            return xeonserv().delete('/domains/' + this.domain.id + '/records', { data: record }).then(this.loadRecords)
        },
        createRecord() {
            xeonserv().post('/domains/' + this.domain.id + '/records', this.serialize(this.record)).then(this.loadRecords).then(() => this.open = false)
        },
        updateRecord() {
            this.deleteRecord(this.oldRecord).then(() => this.createRecord())
        },
        serialize(source) {
            const record = {
                type: source.type,
                name: source.name.length == 0 ? '@' : source.name,
                ttl: 300,
                data: ''
            }
            switch(source.type) {
                case 'A':
                case 'AAAA':
                    record.data = source.address
                    break
                case 'MX': {
                    let host = source.host
                    if(!host.endsWith('.'))
                        host += '.'
                    record.data = source.priority + ' ' + host
                    break
                }
                case 'CNAME': {
                    record.data = source.host
                    if(!record.data.endsWith('.'))
                        record.data += '.'
                    break
                }
                case 'TXT':
                    record.data = '"' + source.text + '"'
                    break
                case 'SRV': {
                    record.name = source.srvName + '.' + source.srvProtocol + (record.name == '@' ? '' : ('.' + record.name))
                    record.data = source.priority + ' ' + source.weight + ' ' + source.port + ' ' + source.host
                    if(!record.data.endsWith('.'))
                        record.data += '.'
                    break
                }
            }
            return record
        },
        deserialize(source) {
            const record = {...DEFAULT_RECORD}
            record.type = source.type
            record.name = source.name
            if(record.name == '@')
                record.name = ''
            switch(record.type) {
                case 'A':
                case 'AAAA':
                    record.address = source.data
                    break
                case 'SRV': {
                    let spl = record.name.split('.')
                    record.srvProtocol = spl[1]
                    record.srvName = spl[0]
                    record.srvService = 'OTHER'
                    record.name = spl.slice(2).join('.')
                    if(record.srvProtocol == '_tcp' && record.srvName == '_minecraft')
                        record.srvService = 'MINECRAFT'
                    if(record.srvProtocol == '_udp' && record.srvName == '_ts3')
                        record.srvService = 'TEAMSPEAK'
                    spl = source.data.split(' ')
                    record.priority = parseInt(spl[0])
                    record.weight = parseInt(spl[1])
                    record.port = parseInt(spl[2])
                    record.host = spl[3].slice(0, spl[3].length - 1)
                    break
                }
                case 'TXT':
                    console.log(record)
                    record.text = source.data.slice(1, source.data.length - 1)
                    break
                case 'CNAME':
                    record.host = source.data.slice(0, source.data.length - 1)
                    break
                case 'MX': {
                    const spl = record.data.split(' ')
                    record.priority = parseInt(spl[0])
                    record.host = spl[1].slice(0, spl[1].length - 1)
                }
            }
            return record
        },
        formatType(type) {
            switch(type) {
                case 'A':
                    return 'A (IP)'
                case 'AAAA':
                    return 'AAAA (IPv6)'
                case 'MX':
                    return 'MX (Mailserver)'
                case 'TXT':
                    return 'TXT'
                case 'SRV':
                    return 'SRV (Service)'
                case 'CNAME':
                    return 'CNAME'
            }
        }
    }
}
</script>