<template>
    <div class="px-page mt-5">
        <v-tabs fixed-tabs v-model="tab">
            <v-tab>
                Allgemein
            </v-tab>
            <v-tab>
                DNS-Einträge
            </v-tab>
            <v-tab>
                Einstellungen
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <DomainGeneral :domain="domain" :service="service" />
            </v-tab-item>
            <v-tab-item>
                <DomainRecords :domain="domain" />
            </v-tab-item>
            <v-tab-item>
                <DomainSettings :domain="domain" />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import xeonserv from '../../../apis/xeonserv'
import DomainGeneral from './DomainGeneral'
import DomainRecords from './DomainRecords'
import DomainSettings from './DomainSettings'

export default {
    components: { DomainGeneral, DomainRecords, DomainSettings },
    data() {
        return {
            tab: 0,
            domain: {
                id: this.$route.params.id,
                sld: '',
                tld: '',
                ns1: '',
                ns2: ''
            },
            service: {
                id: 0,
                expired: false
            }
        }
    },
    created() {
        xeonserv().get('/domains/' + this.domain.id).then(res => this.domain = res.data.data)
        xeonserv().get('/domains/' + this.domain.id + '/service').then(res => this.service = res.data.data)
        this.$on('setdomain', domain => this.domain = domain)
    }
}
</script>